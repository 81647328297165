<template>
  <table
    v-if="$store.state.window.width > 1570"
  >
    <thead>
      <tr>
        <th>
          #
        </th>

        <th>
          {{ $t('views.default.transaction.orders.order-date') }}
        </th>

        <th>
          {{ $t('views.default.transaction.orders.product') }}
        </th>

        <th>
          {{ $t('views.default.transaction.orders.payment-method') }}
        </th>

        <th>
          {{ $t('views.default.transaction.orders.amount') }}
        </th>

        <th>
          {{ $t('views.default.transaction.orders.purchase-id') }}
        </th>
      </tr>
    </thead>

    <tbody>
      <template v-if="data.length > 0">
        <tr
          v-for="item in data"
          :key="item.id"
        >
          <td class="c1">
            {{ item.id }}
          </td>

          <td class="c2">
            {{ $format.dateTime(item.created_at) }}
          </td>

          <td class="c3">
            {{ $t('views.default.transaction.orders.container') }} {{ item.container.name }}
          </td>

          <td class="c4">
            {{ item.currency.name }}
          </td>

          <td class="c5">
            $ {{ $format.wallet(item.amount, ',') }}
          </td>

          <td class="c6">
            {{ item.purchase_id ? item.purchase_id : '----' }}
          </td>

          <td class="c7">
            <div
              class="c7__container"
              :class="
                item.payment_expired === 1 ? 'error'
                  : item.payment_success === 1 ? 'paid'
                    : 'expected'
              "
            >
              {{ item.payment_expired === 1 ? $t('views.default.transaction.orders.payment-error') : item.payment_success === 1 ? $t('views.default.transaction.orders.paid') : $t('views.default.transaction.orders.payment-expected') }}
            </div>

            <button
              v-if="item.payment_expired === 0 && item.payment_success === 0"
              class="c7__repeat"
              @click="$router.push({ name: 'Payment', params: { order: item.id } })"
            >
              <svg
                width="16" height="16"
                style="min-width: 16px; max-height: 16px;"
                viewBox="0 0 16 16" fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M9.75489 13.3807C9.4855 13.4058 9.21503 13.3451 8.98229 13.207C8.81224 13.0324 8.73071 12.7901 8.76071 12.5482C8.76699 12.3469 8.79103 12.1464 8.83257 11.9493C8.87276 11.7232 8.9247 11.4993 8.98826 11.2785L9.69498 8.84692C9.76786 8.60692 9.81604 8.36007 9.83873 8.11024C9.83873 7.84075 9.87466 7.65505 9.87466 7.54727C9.88969 7.06691 9.68458 6.60596 9.31766 6.29555C8.86633 5.94906 8.30429 5.77875 7.73653 5.81642C7.32967 5.82255 6.92595 5.88915 6.53869 6.01406C6.11546 6.14582 5.67026 6.30352 5.20312 6.48722L4.99951 7.27778C5.13724 7.22986 5.30498 7.17598 5.4966 7.11607C5.67942 7.06192 5.86893 7.03366 6.05957 7.03222C6.327 7.00325 6.59618 7.06892 6.82018 7.21787C6.97232 7.3994 7.04371 7.63523 7.01782 7.87071C7.01715 8.0721 6.99507 8.27289 6.95194 8.4696C6.90999 8.67923 6.8561 8.90081 6.79022 9.13437L6.07753 11.5779C6.02009 11.8051 5.97413 12.0349 5.9398 12.2667C5.9118 12.4651 5.8978 12.6652 5.89785 12.8656C5.89492 13.3492 6.11608 13.807 6.49675 14.1053C6.95503 14.4572 7.5251 14.6316 8.10181 14.5964C8.5079 14.6048 8.9126 14.5461 9.29965 14.4227C9.63903 14.3069 10.0922 14.1412 10.6592 13.9256L10.8509 13.171C10.6973 13.2347 10.5391 13.2867 10.3777 13.3267C10.1736 13.3733 9.96401 13.3914 9.75489 13.3807Z" fill="#202539"/>
                <path d="M10.5047 1.85158C10.1787 1.55223 9.74924 1.39118 9.30681 1.4024C8.86464 1.39242 8.43559 1.55331 8.10897 1.85158C7.51028 2.36781 7.44343 3.27166 7.95971 3.8704C8.00572 3.92377 8.0556 3.97364 8.10897 4.01966C8.79104 4.62972 9.82258 4.62972 10.5046 4.01966C11.1033 3.49833 11.166 2.59041 10.6447 1.99173C10.6014 1.94185 10.5545 1.89502 10.5047 1.85158Z" fill="#202539"/>
              </svg>
            </button>

            <button
              v-else
              class="c7__repeat hide"
            />
          </td>
        </tr>
      </template>

      <tr
        v-else
        class="none"
      >
        <td>
          -
        </td>

        <td>
          -
        </td>

        <td>
          -
        </td>

        <td>
          -
        </td>

        <td>
          -
        </td>

        <td>
          -
        </td>

        <td>
          -
        </td>
      </tr>
    </tbody>
  </table>

  <div
    v-else
    class="list"
  >
    <template v-if="data.length > 0">
      <div
        v-for="item in data"
        :key="item.id"
        class="list__item"
      >
        <div class="list__item__start">
          <p class="small-text">
            {{ $format.dateTime(item.created_at) }}
          </p>

          <p class="small-text">
            {{ item.id }}
          </p>
        </div>

        <div class="list__item__center">
          <p class="name">
            {{ $t('views.default.transaction.orders.container') }} {{ item.container.name }}
          </p>

          <div class="list__item__center__row">
            <p class="little-text">
              {{ $t('views.default.transaction.orders.payment-method') }}
            </p>

            <p class="small-text">
              {{ item.currency.name }}
            </p>
          </div>

          <div class="list__item__center__row">
            <p class="little-text">
              {{ $t('views.default.transaction.orders.purchase-id') }}
            </p>

            <p class="small-text gray">
              {{ item.purchase_id ? item.purchase_id : '----' }}
            </p>
          </div>

          <div class="list__item__center__row">
            <p class="little-text">
              {{ $t('views.default.transaction.orders.amount') }}
            </p>

            <p class="count">
              $ {{ $format.wallet(item.amount, ',') }}
            </p>
          </div>
        </div>

        <div class="list__item__end">
          <div
            class="list__item__end__status"
            :class="item.payment_expired === 1 ? 'error' : item.payment_success === 1 ? 'paid' : 'expected'"
          >
            {{ item.payment_expired === 1 ? $t('views.default.transaction.orders.payment-error') : item.payment_success === 1 ? $t('views.default.transaction.orders.paid') : $t('views.default.transaction.orders.payment-expected') }}
          </div>

          <button
            v-if="item.payment_expired === 0 && item.payment_success === 0"
            class="list__item__end__info"
            @click="$router.push({ name: 'Payment', params: { order: item.id } })"
          >
            <svg
              width="16" height="16"
              style="min-width: 16px; max-height: 16px;"
              viewBox="0 0 16 16" fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M9.75489 13.3807C9.4855 13.4058 9.21503 13.3451 8.98229 13.207C8.81224 13.0324 8.73071 12.7901 8.76071 12.5482C8.76699 12.3469 8.79103 12.1464 8.83257 11.9493C8.87276 11.7232 8.9247 11.4993 8.98826 11.2785L9.69498 8.84692C9.76786 8.60692 9.81604 8.36007 9.83873 8.11024C9.83873 7.84075 9.87466 7.65505 9.87466 7.54727C9.88969 7.06691 9.68458 6.60596 9.31766 6.29555C8.86633 5.94906 8.30429 5.77875 7.73653 5.81642C7.32967 5.82255 6.92595 5.88915 6.53869 6.01406C6.11546 6.14582 5.67026 6.30352 5.20312 6.48722L4.99951 7.27778C5.13724 7.22986 5.30498 7.17598 5.4966 7.11607C5.67942 7.06192 5.86893 7.03366 6.05957 7.03222C6.327 7.00325 6.59618 7.06892 6.82018 7.21787C6.97232 7.3994 7.04371 7.63523 7.01782 7.87071C7.01715 8.0721 6.99507 8.27289 6.95194 8.4696C6.90999 8.67923 6.8561 8.90081 6.79022 9.13437L6.07753 11.5779C6.02009 11.8051 5.97413 12.0349 5.9398 12.2667C5.9118 12.4651 5.8978 12.6652 5.89785 12.8656C5.89492 13.3492 6.11608 13.807 6.49675 14.1053C6.95503 14.4572 7.5251 14.6316 8.10181 14.5964C8.5079 14.6048 8.9126 14.5461 9.29965 14.4227C9.63903 14.3069 10.0922 14.1412 10.6592 13.9256L10.8509 13.171C10.6973 13.2347 10.5391 13.2867 10.3777 13.3267C10.1736 13.3733 9.96401 13.3914 9.75489 13.3807Z" fill="#202539"/>
              <path d="M10.5047 1.85158C10.1787 1.55223 9.74924 1.39118 9.30681 1.4024C8.86464 1.39242 8.43559 1.55331 8.10897 1.85158C7.51028 2.36781 7.44343 3.27166 7.95971 3.8704C8.00572 3.92377 8.0556 3.97364 8.10897 4.01966C8.79104 4.62972 9.82258 4.62972 10.5046 4.01966C11.1033 3.49833 11.166 2.59041 10.6447 1.99173C10.6014 1.94185 10.5545 1.89502 10.5047 1.85158Z" fill="#202539"/>
            </svg>
          </button>
        </div>
      </div>
    </template>

    <div
      v-else
      class="list__item none"
    >
      ---
    </div>
  </div>

  <div
    v-if="data.length < count"
    class="controls"
  >
    <Pagination
      :name="$route.name"
      :pages="_countItemPagination"
      :load-more="data.length < count"
      :loading="isFetchMore"
      @loadMore="fetchMore()"
    />
  </div>
</template>

<script>
import Pagination from '@/ui/Pagination.vue'

export default {
  components: {
    Pagination
  },
  data () {
    return {
      mountFetch: false,
      data: [],
      count: 0,
      length: 10,
      isFetchMore: false
    }
  },
  computed: {
    _offset () {
      return ((this.$route.params.page ? this.$route.params.page : 1) * (this.length)) - (this.length)
    },
    _countItemPagination () {
      if (this.count) {
        return Math.ceil(this.count / (this.length))
      }

      return 0
    },
    date () {
      return this.$attrs.date
    }
  },
  activated () {
    if (this.mountFetch) {
      this.fetch(false)
    }
  },
  deactivated () {
    if (this.data.length > this.length) {
      this.data = this.data.slice(0, this.length + 1)
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    async fetch (isCacheFetch = true) {
      try {
        isCacheFetch && this.$emit('preloader', true)

        const resp = await this.$axios.get(
          `/private/orders?limit=${this.length}&offset=${this._offset}${this.date?.parse ? `&date_more=${this.date.parse.start}` : ''}${this.date?.parse ? `&date_less=${this.date.parse.end}` : ''}`)

        if (resp.status === 200) {
          this.count = resp.data.count
          this.data = resp.data.data

          if (this.$route.params.page > 1 && this.data.length === 0) {
            this.$store.commit('SET_ERROR', true)
          }
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        isCacheFetch && this.$emit('preloader', false)
        this.mountFetch = true
      }
    },
    async fetchMore () {
      this.isFetchMore = true
      const offset = this._offset + (this.data.length ? this.data.length : 0)
      const link = `/private/orders?offset=${offset}&limit=${this.length}${this.date?.parse ? `&date_more=${this.date.parse.start}` : ''}${this.date?.parse ? `&date_less=${this.date.parse.end}` : ''}`

      try {
        const resp = (await this.$axios.get(link)).data
        this.count = resp.count

        for (const i in resp.data) {
          this.data.push(resp.data[i])
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.isFetchMore = false
      }
    }
  },
  watch: {
    '$route.params.page' () {
      if (this.$route.params.page) {
        this.fetch()
      }
    },
    date () {
      this.fetch()
    }
  }
}
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  border-spacing: 0;

  thead {
    tr {
      th {
        padding: 0 20px 20px 20px;
        color: rgb(var(--text-color-light-gray));
        text-align: left;
        font-size: 12px;
        line-height: 100%;
        font-family: Medium;
      }
    }
  }

  tbody {
    tr {
      &.none {
        font-size: 18px;
        line-height: 100%;
        color: #9CA3B8;
      }

      td {
        padding: 17px 20px;

        &.c1, &.c2 {
          color: rgba(var(--text-color-light-gray));
          font-family: Medium;
          font-size: 14px;
          line-height: 100%;
        }

        &.c3, &.c4 {
          color: rgb(var(--color-dark));
          font-family: Medium;
          font-size: 14px;
          line-height: 100%;
        }

        &.c5 {
          color: rgb(var(--color-dark));
          font-family: Medium;
          font-size: 16px;
          line-height: 100%;
        }

        &.c6 {
          color: rgba(var(--text-color-light-gray));
          font-family: Medium;
          font-size: 14px;
          line-height: 100%;
        }

        &.c7 {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .c7__container {
            padding: 5px 15px;
            border-radius: 30px;
            font-size: 14px;
            line-height: 100%;
            text-align: center;
            font-family: Medium;

            &.paid {
              background: rgba(0, 178, 7, .15);
              color: rgba(0, 178, 7, 1);
            }

            &.error {
              background: rgba(218, 40, 40, .15);
              color: rgba(218, 40, 40, 1);
            }

            &.expected {
              background: rgba(255, 92, 0, .15);
              color: rgba(255, 92, 0, 1);
            }
          }

          .c7__repeat {
            height: 28px;
            width: 28px;
            margin-left: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #D5DDFB;
            border-radius: 50%;
            transition: .2s;

            &.hide {
              opacity: 0;
              cursor: default;
            }

            svg path {
              transition: .2s;
            }

            &:hover {
              background: rgba(87, 127, 255, 1);

              svg path {
                fill: white;
              }
            }

            &:active {
              background: #586BB1;

              svg path {
                fill: white;
              }
            }
          }
        }
      }

      &:nth-child(odd) {
        td {
          background: rgba(240, 242, 248, .5);

          &:first-child {
            border-radius: 10px 0 0 10px;
          }

          &:last-child {
            border-radius: 0 10px 10px 0;
          }
        }
      }
    }
  }
}

.list {
  width: 100%;

  .list__item {
    width: 100%;
    padding: 15px 10px;
    border-radius: 10px;

    &.none {
      font-size: 18px;
      line-height: 100%;
      color: #9CA3B8;
    }

    &:nth-child(odd) {
      background: rgba(240, 242, 248, .5);
    }

    .list__item__start {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;

      p.small-text {
        line-height: 100%;
        color: #787C8D;
        font-family: Medium;
      }
    }

    .list__item__center {
      p.name {
        margin-bottom: 20px;
        font-size: 18px;
        line-height: 100%;
        color: #202539;
        font-family: SemiBold;
      }

      .list__item__center__row {
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          line-height: 100%;
          font-family: Medium;

          &.little-text {
            color: #787C8D;
            font-family: Regular;
          }

          &.small-text {
            color: #202539;

            &.gray {
              color: #787C8D;
            }
          }

          &.count {
            font-size: 18px;
            line-height: 100%;
            text-align: right;
            color: #202539;
            font-family: SemiBold;
          }
        }
      }
    }

    .list__item__end {
      margin-top: 20px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .list__item__end__status {
        padding: 5px 15px;
        border-radius: 30px;
        font-size: 14px;
        line-height: 100%;
        font-family: Medium;

        &.paid {
          background: rgba(0, 178, 7, .15);
          color: rgba(0, 178, 7, 1);
        }

        &.error {
          background: rgba(218, 40, 40, .15);
          color: rgba(218, 40, 40, 1);
        }

        &.expected {
          background: rgba(255, 92, 0, .15);
          color: rgba(255, 92, 0, 1);
        }
      }

      .list__item__end__info {
        height: 28px;
        width: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #D5DDFB;
        border-radius: 50%;
      }
    }
  }
}

.controls {
  width: 100%;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #9CA3B8;
}
</style>
